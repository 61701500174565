


















import Vue from 'vue'
import Component from 'vue-class-component'
import ListView from '@/components/list/ListView.vue'
import { Watch } from 'vue-property-decorator'

@Component({
  components: {
    ListView,
  },
})
export default class List extends Vue {
  private headers = []
  private topActions = []
  private consentTypeName = ''
  private consentTypeUrl = ''

  private get consentTypeId(): number {
    return this.$route.params.consentType ? parseInt(this.$route.params.consentType) : null
  }

  private setupView() {
    switch (this.consentTypeId) {
      case 1:
        this.consentTypeName = 'Email'
        this.consentTypeUrl = '/v4/site/consent/settings/list/' + this.consentTypeId
        break
      case 2:
        this.consentTypeName = 'SMS'
        this.consentTypeUrl = '/v4/site/consent/settings/list/' + this.consentTypeId
        break
      default:
        this.$router.replace('/settings/customer/consent/list/1')
    }
  }

  @Watch('consentTypeId')
  private onConsentTypeIdChanged() {
    this.setupView()
  }

  public created(): void {
    this.setupView()
  }

  public mounted(): void {
    this.headers = [
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'System default',
        value: 'siteId',
        sortable: false,
      },
      {
        text: 'Actions',
        value: 'actions',
      },
    ]

    this.topActions = [
      {
        id: 'new',
        template: 'new',
        route: () => {
          return {
            name: 'Settings/Customer/Consent/New',
            params: {
              id: '0',
              consentType: parseInt(this.$route.params.consentType),
            },
          }
        },
      },
    ]
  }

  private get rowActions() {
    const actions: Array<Object> = [
      {
        id: 'edit',
        visibility: (item) => {
          return !!item.siteId
        },
        route: (item) => {
          return {
            name: 'Settings/Customer/Consent/Edit',
            params: {
              id: item.id,
              consentType: parseInt(this.$route.params.consentType),
            },
          }
        },
      },
    ]
    actions.push({
      id: 'delete',
      visibility: (item) => {
        return !!item.siteId
      },
      deleteUrl: '/v4/site/consent/settings',
    })
    return actions
  }
}
